// eslint-disable-next-line
const template = [
    // {
    //     title: "",
    //     image: require(""),
    //     imdb: "",
    // }
]

export const nowPlaying = [
    {
        title: "Gremlins (1984)",
        image: require("../src/assets/gremlins-1984.webp"),
        imdb: "https://www.imdb.com/title/tt0087363/"
    },
    {
        title: "Die Hard (1988)",
        image: require("../src/assets/die-hard-1988.webp"),
        imdb: "https://www.imdb.com/title/tt0095016/",
    }
]

export const toBeDetermined = [
    {
        title: "To Be Determined",
        image: require("./assets/to-be-determined.jpg"),
        imdb: "",
    },
    {
        title: "To Be Determined",
        image: require("../src/assets/to-be-determined.jpg"),
        imdb: "",
    }
]

// eslint-disable-next-line
export const comingSoon = [
    {
        title: "How the Grinch Stole Christmas (2000)",
        image: require("../src/assets/how-the-grinch-stole-christmas-2000.webp"),
        imdb: "https://www.imdb.com/title/tt0170016/",
    },
    {
        title: "Home Alone (1990)",
        image: require("../src/assets/home-alone-1990.webp"),
        imdb: "https://www.imdb.com/title/tt0099785/    ",
    }
]
